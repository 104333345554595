// Header.js
import React from 'react';
import {  useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = ({ authenticated, setAuthenticated }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Perform any logout logic (e.g., clearing authentication state)
    setAuthenticated(false);
    // Navigate to the login page
    navigate('/login');
  };

  return (
    <header className="header-container">
      <div className="header-row">
         <h1>Dashboard  <Link to="/"> 
          
          <button className="home-button">Home</button>
        </Link></h1>
        {/* <img src="/dz.png" alt="name" /> */}
      </div>
      <div className="header-row">
      {authenticated ? (
          <button className="logout-button" onClick={handleLogout}>Logout</button>
        ) : (
          <nav>
            {/* Add navigation links if needed */}
          </nav>
        )}
        <nav>
          {/* Add navigation links if needed */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
