// Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = ({ setAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    // Perform authentication logic (e.g., check against backend API)
    // For simplicity, let's use a hardcoded username and password
    const validUsername = 'Emmela_Tk';
    const validPassword = 'Deals4#@97';

    if (username === validUsername && password === validPassword) {
      // Authentication successful
      setAuthenticated(true);
      navigate('/'); // Redirect to the home page
    } else {
      // Authentication failed
      alert('Invalid username or password');
    }
  };

return (
    <div className="login-container">
         <div className="login-header">
      {/* <p><DashboardIcon /> </p> */}
      <span  className="welcome-back">Welcome back</span>
      <p className="instruction">Enter your username and password to signin to your</p>
      <p className="instruction">account</p>
      </div>
        
      <table className="login-table">
       
        <tbody>
            
          <tr>
            {/* <td>
              <label>Username:</label>
            </td> */}
            <td>
            <input
              id="username"
              placeholder="Enter Username"
              type="text"
              value={username}
                onChange={(e) => setUsername(e.target.value)}
             
              
            />
            </td>
          </tr>
          <tr>
            {/* <td>
              <label>Password:</label>
            </td> */}   
            <td>
              <input
              id="password"
              placeholder="Enter Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <button onClick={handleLogin}>Login</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Login;
