
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import salesData from './counts_sales.json';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SalesChart = ({ user }) => {
  
  const data = salesData

  // useEffect(() => {
  //   // Assuming salesData is structured appropriately with states and categories
  //   setData(salesData);
  // }, []);

  if (!user) {
    return <div>Please log in to view this page.</div>;
  }

  const categories = Object.keys(data);
  const states = Object.keys(data[categories[0]]); // Assuming all categories have the same states

  const datasets = categories.map((category, index) => ({
    label: category,
    data: states.map((state) => data[category][state]),
    backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.6)`,
    borderColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`,
    borderWidth: 1,
  }));

  const chartData = {
    labels: states,
    datasets: datasets,
  };

  const chartOptions = {
    scales: {
      x: { type: 'category', stacked: true },
      y: { type: 'linear', stacked: true, beginAtZero: true },
    },
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '600px',
        width: '1000px',
      }}
    >
      <h2>Sales Counts by Category and State</h2>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default SalesChart;

