import React, { useState, useEffect } from 'react';
import jsonData from './json_data.json';

const PAGE_SIZE = 20;

const All = ({ user }) => {
  const [dealerships, setDealerships] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setDealerships(jsonData);
  }, []);

  if (!user) {
    // Redirect or show an error message if the user is not logged in
    return <div>Please log in to view this page.</div>;
  }

  // Calculate the range of indices for the current page
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const currentDealerships = dealerships.slice(startIndex, endIndex);

  const totalPages = Math.ceil(dealerships.length / PAGE_SIZE);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // height: 'flex',
      width: '100%'
    }}>
      <h2>Dealerships Data</h2>
      <div style={{ marginTop: '10px' }}>
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span style={{ margin: '0 10px' }}>{`Page ${currentPage} of ${totalPages}`}</span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
      <br></br>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      <thead>
          <tr>
           <th style={cellStyle}>Manufacture</th>
         <th style={cellStyle}>Dealership Name</th>
             <th style={cellStyle}>Dealer Owner</th>
             <th style={cellStyle}>region</th>
             <th style={cellStyle}>State</th>
             <th style={cellStyle}>Distict</th>
             <th style={cellStyle}> Facility Type</th>
             <th style={cellStyle}>Address_1</th>
             <th style={cellStyle}>Address_2</th>
             <th style={cellStyle}>pincode</th>
           </tr>
        </thead>
        <tbody>
          {currentDealerships.map((dealership, index) => (
            <tr key={index}>
             <td style={cellStyle}>{dealership.manufacture}</td>
              <td style={cellStyle}>{dealership.dealership_name}</td>
               <td style={cellStyle}>{dealership.dealer_owner}</td>
               <td style={cellStyle}>{dealership.region}</td>
               <td style={cellStyle}>{dealership.state}</td>
               <td style={cellStyle}>{dealership.district}</td>
               <td style={cellStyle}>{dealership.facility_type}</td>
               <td style={cellStyle}>{dealership.address_1}</td>
              <td style={cellStyle}>{dealership.address_2}</td>
               <td style={cellStyle}>{dealership.pincode}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br></br>
      
    </div>
  );
};

const cellStyle = {
  border: '1px solid #ddd',
  padding: '8px',
  textAlign: 'left',
};

export default All;

// ... Rest of the component
