// CombinedChart.js
import React from 'react';
import { Pie } from 'react-chartjs-2';
import counts from './counts_sales.json';
import { Chart, DoughnutController, CategoryScale, Title, Tooltip, Legend,ArcElement } from 'chart.js';

Chart.register(DoughnutController, CategoryScale, Title, Tooltip, Legend,ArcElement);

const CombinedChart = ({ user }) => {
  if (!user) {
    // Redirect or show an error message if the user is not logged in
    return <div>Please log in to view this page.</div>;
  }

  const combinedData = counts;

  const categories = Object.keys(combinedData);
  const states = Object.keys(combinedData[categories[0]]);

  const datasets = states.map((state, index) => {
    const data = categories.map((category) => combinedData[category][state]);
    const backgroundColor = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.6)`;

    return {
      label: state,
      data: data,
      backgroundColor: backgroundColor,
      borderColor: backgroundColor,
      borderWidth: 1,
    };
  });

  const chartData = {
    labels: categories,
    datasets: datasets,
  };

  const chartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Sales & Services Counts',
        position: 'top',
      },
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}>
      <Pie data={chartData} options={chartOptions} />
    </div>
  );
};

export default CombinedChart;
