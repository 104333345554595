import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
// import jsondata from './newgraph.json'; // Corrected import

const NewGraph = ({ user }) => {
  const [stateData, setStateData] = useState(null);
  const [selectedState, setSelectedState] = useState("Karnataka");
  const chartRef = useRef(null);

  useEffect(() => {
    // Fetch data from the JSON file in the public folder
    fetch('/newgraph.json')
      .then(response => response.json())
      .then(data => setStateData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);


  useEffect(() => {
    if (stateData && chartRef.current) { // Add this condition
      const ctx = chartRef.current.getContext('2d');

      const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Sales', 'Services', 'Sales + Services'],
          datasets: [
            {
              label: 'Toyota',
              data: [
                stateData[selectedState]["Toyota"].sales.reduce((a, b) => a + b, 0),
                stateData[selectedState]["Toyota"].services.reduce((a, b) => a + b, 0),
                stateData[selectedState]["Toyota"].sales_services.reduce((a, b) => a + b, 0)
              ],
              backgroundColor: getRandomColor(),
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1
            },
            {
              label: 'MarutiSuziki',
              data: [
                stateData[selectedState]["MarutiSuziki"].sales.reduce((a, b) => a + b, 0),
                stateData[selectedState]["MarutiSuziki"].services.reduce((a, b) => a + b, 0),
                stateData[selectedState]["MarutiSuziki"].sales_services.reduce((a, b) => a + b, 0)
              ],
              backgroundColor: getRandomColor(),
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1
            }
          ]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                radius: 5
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                boxWidth: 20,
                usePointStyle: true
              }
            }
          }
        },
        // Add custom chart title to display count above bars
        plugins: {
          title: {
            display: true,
            text: 'Total Counts',
            align: 'start',
            font: {
              size: 18,
            },
            color: 'black',
            padding: {
              top: 10,
              bottom: 20
            },
            // Custom function to display count above each bar
            render: (args) => {
              const { chart, ctx } = args;
              const { datasets } = chart.data;
              datasets.forEach((dataset, i) => {
                const meta = chart.getDatasetMeta(i);
                meta.data.forEach((bar, index) => {
                  const data = dataset.data[index];
                  const xPos = bar.x;
                  const yPos = bar.y;
                  ctx.save();
                  ctx.fillStyle = 'black';
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'bottom';
                  ctx.fillText(data, xPos, yPos - 5);
                  ctx.restore();
                });
              });
            }
          }
        }
      });

      return () => {
        myChart.destroy();
      };
    }
  }, [stateData, selectedState]);

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  if (!user) {
    // Redirect or show an error message if the user is not logged in
    return <div>Please log in to view this page.</div>;
  }

  return (
    <div style={{ padding: '40px' }}>
      <div style={{ padding: '20px' }}>
        <label htmlFor="state-select" style={{ padding: '10px' }}>Select State:</label>
        <select id="state-select" value={selectedState} onChange={handleStateChange} >
          <option value="Karnataka">Karnataka</option>
          //<option value="Arunachal Pradesh">Arunachal Pradesh</option>
          //<option value="Assam">Assam</option>
        </select> 
      </div>
      <canvas id="bar-chart" ref={chartRef}></canvas>
    </div>
  );
};

export default NewGraph;

