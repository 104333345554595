// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-row">
        <p>Contact Information</p>
      </div>
      <div className="footer-row">
        <p>Copyright © 2023 Datazeneral</p>
      </div>
    </footer>
  );
};

export default Footer;
