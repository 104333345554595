// // App.js
// import React, { useState } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Header from './Header';
// import Footer from './Footer';
// import Home from './Home';
// import SalesChart from './SalesChart';
// import ServicesChart from './ServicesChart';
// import CombinedChart from './CombinedChart';
// import All from './All_data';
// import Login from './Login';

// const App = () => {
//   const [user, setUser] = useState(null);

//   const handleLogin = (credentials) => {
//     // For simplicity, just set the user to a non-null value
//     setUser({ username: credentials.username });
//   };

//   return (
//     <Router>
//       <Header user={user} />
//       <Routes>
//       <Route path="/login" element={<Login onLogin={handleLogin} />} />
//         <Route path="/" element={<Home user={user} />} />
//         <Route path="/sales" element={<SalesChart user={user} />} />
//         <Route path="/services" element={<ServicesChart user={user} />} />
//         <Route path="/combined" element={<CombinedChart user={user} />} />
//         <Route path="/all" element={<All user={user} />} />
//         <Route
//           path="/login"
//           element={<Login onLogin={handleLogin} />}
//         />
//       </Routes>
//       <Footer />
//     </Router>
//   );
// };

// export default App;

// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import SalesChart from './SalesChart';
import ServicesChart from './ServicesChart';
import CombinedChart from './CombinedChart';
import Login from './Login';
import All from './All_data';
import NewGraph from './NewGraph';

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  return (
    <Router>
      <Header authenticated={authenticated} setAuthenticated={setAuthenticated} />
      <Routes>
        <Route
          path="/"
          element={authenticated ? <Home user={authenticated} /> : <Navigate to="/login" />}
        />
        <Route path="/sales" element={<SalesChart user={authenticated}/>} />
        <Route path="/services" element={<ServicesChart user={authenticated}/>} />
        <Route path="/combined" element={<CombinedChart user={authenticated}/>} />
        <Route path="/all" element={<All user={authenticated} />} />
        <Route path="/analytics" element={<NewGraph user={authenticated} />} />
        <Route
          path="/login"
          element={<Login setAuthenticated={setAuthenticated} />}
        />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;

