// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Home.css';


const Home = ({user}) => {
  const navigate = useNavigate();
  if (user) {
  return (
    
    <div className="home-container">
      <h1>Welcome to Your Datazeneral</h1>
      <div className="button-container">
        <Link to="/sales">
          <button className="sales-button">Sales</button>
        </Link>
        <Link to="/services">
          <button className="services-button">Services</button>
        </Link>
        <Link to="/combined">
          <button className="combined-button"> Sales & Services</button>
        </Link>
        <Link to="/all">
          <button className="sales-button"> All Data</button>
        </Link>
        <Link to="/analytics">
          <button className="new-button"> Analytics </button>
        </Link>
      </div>
    </div>
  );
}
else {
  // User is not authenticated, redirect to the login page
  navigate('/login');
  return null; // Render nothing, as we're redirecting
}};

export default Home;
