// ServicesChart.js
// import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import servicesdata from './counts_services.json';
import { LineElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip, PointElement } from 'chart.js';

Chart.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement);

const ServicesChart = ({ user }) => {
  const data = servicesdata
  if (!user) {
    // Redirect or show an error message if the user is not logged in
    return <div>Please log in to view this page.</div>;
  }

  const categories = Object.keys(data);
  const states = Object.keys(data[categories[0]]); // Assuming all categories have the same states

  const datasets = categories.map((category, index) => ({
    label: category,
    borderColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`,
    borderWidth: 3,
    pointBackgroundColor: 'rgba(75, 192, 192, 1)',
    pointBorderColor: 'rgba(75, 192, 192, 1)',
    pointRadius: 5,
    pointHoverRadius: 7,
    data: states.map((state) => data[category][state]),
  }));

  const chartData = {
    labels: states,
    datasets: datasets,
  };

  const chartOptions = {
    scales: {
      x: { type: 'category', stacked: true },
      y: { type: 'linear', stacked: true, beginAtZero: true },
    },
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '600px',
        width: '1000px',
      }}
    >
      <h2>Services Counts by Category and State</h2>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default ServicesChart;
